<template>
  <div class="page-wrap">
    <LayoutsCommonAside :open-status="openStatus" />
    <div class="page-wrap__content" :class="openStatus ? 'active' : ''">
      <LayoutsCommonHeader :open-status="openStatus" @change-open-status="handleChangeOpenStatus" />
      <main class="page__main">
        <div class="page__main-container">
          <NuxtPage />
        </div>
      </main>
    </div>
    <el-dialog
      v-model="emailVerificationVisible"
      title="메일 확인"
      center
      width="500px"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <EmailVerification @success="handleSuccessVerificationEmail()"/>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { getUserInfo } from "~/composables/java/auth";

const emailVerificationVisible = ref(false);

const openStatus = ref(window.innerWidth > 1024 ? true : false);
const authStore = useAuthStore();


onMounted(() => {
  if (authStore.isLogin === false) {
    useRouter().push({ name: 'login' });
    return;
  }

  if (authStore.user_classify === 'teacher' && !authStore.user_email_verified) {
    emailVerificationVisible.value = true;
  }
});

function handleSuccessVerificationEmail() {
  getUserInfo().then((res) => {
    if (res.data.user_info) {
      emailVerificationVisible.value = false;
      useRouter().go(0);
    } else {
      ElMessage.error(res.data.message || res.data.error || '회원 정보를 불러오지 못했습니다.');
    }
  }).catch(() => {
    ElMessage.error('메일 확인에 실패했습니다. 네트워크 상태를 확인해주세요.');
  });
}

function handleChangeOpenStatus() {
  openStatus.value = !openStatus.value;
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/layouts/default.scss';
</style>
